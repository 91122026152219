import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// Container for overall layout with flexbox for centering the cards
const Container = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '20px',
      }}
    >
      {children}
    </div>
  );
};

// Individual card with clickable image
const CategoryCard = ({ category }) => {
  const navigate = useNavigate();

  // Handler for storing categoryId in localStorage and navigating to the menu page
  const handleCardClick = () => {
    localStorage.setItem('selectedCategoryId', category.categoryId); // Store categoryId in localStorage
    navigate('/menu'); // Navigate to the menu page without the categoryId as a parameter
  };

  return (
    <div
      style={{
        width: '300px',
        textAlign: 'center',
        border: '1px solid #ddd',
        borderRadius: '16px', // Rounded corners
        padding: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
        overflow: 'hidden', // Ensures content stays inside the card
        cursor: 'pointer', // Change cursor to pointer on hover
      }}
      onClick={handleCardClick} // Attach click handler to the card
    >
      <img
        src={`data:image/png;base64,${category.image}`} // Base64 image source
        alt={category.categoryName}
        onError={(e) => {
          e.target.src = 'fallback-image.png';
        }} // Fallback image if the image fails to load
        style={{
          width: '100%',
          height: '180px', // Fixed height for the image
          objectFit: 'cover', // Ensure image scales properly
          borderRadius: '16px 16px 0 0', // Rounded top corners for the image
        }}
      />
      <h3 style={{ padding: '10px 0', fontSize: '18px', fontWeight: 'bold' }}>{category.categoryName}</h3>
    </div>
  );
};

const BrowseCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get('https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/categories/getall')
      .then((response) => {
        setCategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <h3 style={{ textAlign: 'center' }}>Loading categories...</h3>;
  }

  if (error) {
    return <h3 style={{ textAlign: 'center', color: 'red' }}>{error}</h3>;
  }

  return (
    <div>
      <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Browse Categories</h2>
      <Container>
        {categories.map((category) => (
          <CategoryCard key={category.categoryId} category={category} />
        ))}
      </Container>
    </div>
  );
};

export default BrowseCategories;
