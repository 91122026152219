import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {

    const navigate = useNavigate();
   
    // const handleLogout = () => {
    //     // Add your logout functionality here
    //     console.log("Logging out...");
    //     navigate('/home'); 
    // };

    const [userData, setUserData] = useState({
        username: "Raeyu",
        mobile_number: "764456677118",
        email_id: "rcreddy@gmail.com",
        addressLine: "gyyy vgg ggy",
        city: "Bihar",
        state: "334441",
        pincode: "334441"
      });
    
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedField, setSelectedField] = useState(null);
      const [editValue, setEditValue] = useState("");
    
      const handleShowModal = (field) => {
        setSelectedField(field);
        setEditValue(userData[field]);
        setShowEditModal(true);
      };
    
      const handleSave = () => {
        setUserData((prevData) => ({ ...prevData, [selectedField]: editValue }));
        setShowEditModal(false);
      };

      

    return (
        <div className="profile-container">
        <section id="vertical_tab_nav">
          <ul className="tabsd">
            <li>
              <Link to="/profile" className="tab active">
                <i className="fas fa-user profile-icon"></i> Profile
              </Link>
            </li>
            <li>
              <Link to="/orders" className="tab">
                <i className="fas fa-shopping-cart profile-icon"></i> Orders
              </Link>
            </li>
            <li>
              <Link to="/payments" className="tab">
                <i className="fas fa-credit-card profile-icon"></i> Payments
              </Link>
            </li>
            <li>
              <a href="/home" className="tab">
                <i className="fas fa-sign-out-alt profile-icon"></i> Logout
              </a>
            </li>
          </ul>
        </section>
  
        <div className="profile-content">
          {/* Profile content goes here */}
          <div className="tab_containerdv">
      <h3 className="tab_drawer_headingss">Profile</h3>
      <article id="tab1" style={{ marginTop: "20px" }}>
        <div className="container def">
          <div className="row justify-content-center revss">
            <div className="col-md-10">
              <div className="card-contentss" style={{ marginTop: "20px" }}>
                <div className="table-responsive">
                  <table className="table table-bordered" style={{ height: "285px" }}>
                    <tbody>
                      {/* Name */}
                      <tr>
                        <th>Name</th>
                        <td style={{ fontSize: "17px" }}>
                          {userData.username} <i className="feather-edit" />
                        </td>
                        <td className="action-buttonss">
                          <i
                            className="fa fa-edit"
                            style={{ fontSize: "18px", color: "#685e5e" }}
                            onClick={() => handleShowModal("username")}
                          />
                        </td>
                      </tr>

                      {/* Mobile Number */}
                      <tr>
                        <th>Number</th>
                        <td style={{ fontSize: "17px" }}>
                          {userData.mobile_number}
                        </td>
                        <td className="action-buttonss">
                          <i
                            className="fa fa-edit"
                            style={{ fontSize: "18px", color: "#685e5e" }}
                            onClick={() => handleShowModal("mobile_number")}
                          />
                        </td>
                      </tr>

                      {/* Email */}
                      <tr>
                        <th>Email</th>
                        <td style={{ fontSize: "17px" }}>{userData.email_id}</td>
                        <td className="action-buttonss">
                          <i
                            className="fa fa-edit"
                            style={{ fontSize: "18px", color: "#685e5e" }}
                            onClick={() => handleShowModal("email_id")}
                          />
                        </td>
                      </tr>

                      {/* Address */}
                      <tr>
                        <th>Address</th>
                        <td id="addressField" style={{ fontSize: "17px" }}>
                          {userData.addressLine}, {userData.city}, {userData.state}, {userData.pincode}
                        </td>
                        <td className="action-buttonss">
                          <Button
                            className="btn"
                            style={{ color: "#fff", backgroundColor: "#ee4b2e", marginRight: "8px" }}
                            onClick={() => handleShowModal("addressLine")}
                          >
                            Add
                          </Button>
                          <br />
                          <i
                            className="fa fa-edit"
                            style={{ fontSize: "18px", color: "#685e5e", paddingTop: "10px" }}
                            onClick={() => handleShowModal("addressLine")}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      {/* Edit Modal */}

      
          {/* This is where you can insert the profile info like name, number, email, etc. */}
        </div>
      </div>
      <div>
      <Modal
      style={{marginTop:'100px'}}
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName="custom-modal-position" // Apply custom class
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {selectedField}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
      </div>                      
    );
};

export default ProfilePage;
