import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import logo from "../img/MAAMAAS_LOGO.png";

const Signup = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [userName, setUserName] = useState(""); // State for storing userName
  const [mobileNumber, setMobileNumber] = useState(""); // State for storing mobileNumber
  const [emailId, setEmailId] = useState(""); // State for storing emailId
  const [password, setPassword] = useState(""); // State for storing password
  const [signupErrorMessage, setSignupErrorMessage] = useState(""); // To display signup error messages
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  // Handle signup
  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader when signup begins

    const requestBody = {
      userName: userName,
      password: password,
      emailId: emailId,
      mobileNumber: mobileNumber,
    };

    try {
      const response = await fetch(
        "https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/user/registration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const textResponse = await response.text();

      if (response.ok) {
        alert("Successfully Signed Up");
        navigate("/login");
      } else {
        setSignupErrorMessage(textResponse || "Signup failed, please try again.");
      }
    } catch (error) {
      setSignupErrorMessage("An error occurred during signup. Please try again later.");
    } finally {
      setIsLoading(false); // Hide loader after process
    }
  };

  return (
    <div className="container-fluid">
        {isLoading && ( // Conditionally render the full-page loader
        <div className="loader-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="container">
        <div className="row" style={{ justifyContent: "center", textAlign: "center" }}>
          <div className="container2 p-5">
            <form id="registerForm" onSubmit={handleSignup}>
              <div className="login-logo logo-normal">
                <img src={logo} alt="" style={{ height: "90px" }} />
              </div>
              <h6>Signup</h6>
              <div className="input-boxfull">
                <label>Full Name</label>
                <input
                  id="userName"
                  name="userName"
                  placeholder="Enter Full Name"
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
                <i className="bx bxs-user"></i>
              </div>
              <div className="input-boxmob">
                <label>Mobile Number</label>
                <input
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Enter Mobile Number"
                  type="text"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  required
                />
                <i className="bx bxs-mobile"></i>
              </div>
              <div className="input-boxem">
                <label>Email</label>
                <input
                  id="emailId"
                  name="emailId"
                  type="email"
                  placeholder="Enter Your Email"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  required
                />
                <i className="bx bxs-envelope"></i>
              </div>
              <div className="input-boxpass">
                <label>Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i
        className={`bx ${showPassword ? 'bxs-show' : 'bxs-hide'}`} // Toggle between eye and eye-slash icon
        onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
        style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickability
      ></i>
              </div>
              {/* Error Message for Signup */}
              {signupErrorMessage && (
                <div style={{ color: "red", marginTop: "60px" }}>
                  {signupErrorMessage}
                </div>
              )}
              <button type="submit" className="btnf">Sign Up</button>
              <div className="register-link">
                <p>
                  Already have an account? <a href="/login">Login</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
