import logo from "../img/icon-dinner.png";
import React, { useState, useEffect } from 'react'; // Import useEffect
import axios from 'axios'; // Import axios for API requests

const Carts = ({ isCartVisible, setIsCartVisible, dishDetails }) => {
  const totalQuantity = Object.values(dishDetails).reduce((total, dish) => total + (dish.quantity || 0), 0);
  
  // Correctly define dishDetails and setDishDetails
  const [localDishDetails, setDishDetails] = useState({}); 
  const [isDineIn, setIsDineIn] = useState(true);
  const [loading, setLoading] = useState(false); // State for loading

 
  useEffect(() => {
    const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
    setDishDetails(storedDishDetails);
  }, []);


  const handleToggle = () => {
    setIsDineIn(!isDineIn); // Toggle between Dine In and Take Away
  };
  
  const initiatePayment = async () => {
    setLoading(true);

    // Payment and transaction details
    const apiKey = "74cf3dd4-abf5-4e25-8850-0fd5d3fd1bd1";
    const redirectUrl = 'payment-success.php';
    const orderId = `order_${Date.now()}`;
    const name = "Tutorials Website";
    const email = "info@maamaas.com";
    const mobile = 9333999999;
    const amount = 10; // Amount in INR
    const description = 'Payment for Product/Service';

    const paymentData = {
        merchantId: 'M2203XIOCFUVK',
        merchantTransactionId: "MT7850590068188105",
        merchantUserId: "MUID128",
        amount: amount * 100, // Convert to paisa
        redirectUrl: redirectUrl,
        redirectMode: "POST",
        callbackUrl: redirectUrl,
        mobileNumber: mobile,
        message: description,
        email: email,
        shortName: name,
        paymentInstrument: {
            type: "PAY_PAGE",
        },
    };

    try {
        // Encode payload
        const jsonEncodedData = JSON.stringify(paymentData);
        const payloadMain = btoa(jsonEncodedData); // Base64 encode

        // Generate SHA256 hash
        const saltIndex = 1;
        const payload = `${payloadMain}/pg/v1/pay${apiKey}`;
        const sha256Hash = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(payload));

        // Convert hash to hex string
        const sha256Hex = Array.from(new Uint8Array(sha256Hash))
            .map((b) => b.toString(16).padStart(2, '0'))
            .join('');

        const finalXHeader = `${sha256Hex}###${saltIndex}`;

        // Prepare request body
        const requestBody = JSON.stringify({ request: payloadMain });

        // Make the request using Axios to the proxy endpoint
        const response = await axios.post(
            "/api", // Proxy endpoint, will be rewritten to PhonePe API
            requestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                    "X-VERIFY": finalXHeader, // Custom x-verify header
                    "Accept": "application/json",
                },
            }
        );

        // Handle the response
        const responseData = response.data;
        console.log(responseData);
        if (responseData.success === 200) {
            const paymentUrl = responseData.data.instrumentResponse.redirectInfo.url;
            window.location.href = paymentUrl; // Redirect to the payment page
        } else {
            alert("Payment initiation failed: " + responseData.message);
        }
    } catch (error) {
        console.error("Error initiating payment:", error);
        alert("Error initiating payment. Please try again.");
    } finally {
        setLoading(false);
    }
};



  const updateQuantity = (dishId, delta) => {
    setDishDetails((prevItems) => {
      const updatedItems = { ...prevItems };
      if (updatedItems[dishId]) {
        const currentQuantity = updatedItems[dishId].quantity;
        const newQuantity = currentQuantity + delta;
        if (newQuantity <= 0) {
          delete updatedItems[dishId];
        } else {
          updatedItems[dishId].quantity = newQuantity;
        }
      }
      return updatedItems;
    });
  };

  const calculateTotal = () => {
    const subtotal = Object.values(localDishDetails).reduce((acc, item) => acc + item.price * item.quantity, 0);
    const cgst = subtotal * 0.09;
    const sgst = subtotal * 0.09;
    const grandTotal = subtotal + cgst + sgst;
    return { subtotal, cgst, sgst, grandTotal };
  };

  const { subtotal, cgst, sgst, grandTotal } = calculateTotal();

  return (
    <div id="cart-items" className="cart-container">
      <h5 className="cart-title">View Cart</h5>
      <table className="cart-table">
          <thead>
              <tr>
                  <th>SNO</th>
                  <th>ITEM</th>
                  <th>PRICE</th>
                  <th>QTY</th>
                  <th>TOTAL</th>
              </tr>
          </thead>
          <tbody>
              {Object.values(localDishDetails).map((item, index) => (
                  <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>₹{item.price}</td>
                      <td>
                          <div className="qty-container">
                              <button className="qty-button" onClick={() => updateQuantity(item.dishId, -1)}>-</button>
                              <span className="qty-count">{item.quantity}</span>
                              <button className="qty-button" onClick={() => updateQuantity(item.dishId, 1)}>+</button>
                          </div>
                      </td>
                      <td>₹{(item.price * item.quantity).toFixed(2)}</td>
                  </tr>
              ))}
          </tbody>
      </table>

      {/* Display totals */}
      <div className="cart-totals">
          <p>Total: <span>₹{subtotal.toFixed(2)}</span></p>
          <p>CGST (9%): <span>₹{cgst.toFixed(2)}</span></p>
          <p>SGST (9%): <span>₹{sgst.toFixed(2)}</span></p>
          <p>Grand Total: <span>₹{grandTotal.toFixed(2)}</span></p>

          <div className="cart-options">
              {/* <ToggleButton onToggle={(dineIn) => setIsDineIn(dineIn)} /> */}
              <label className="switch">
        <input
          type="checkbox"
          className="toggleSwitch"
          id="service"
          checked={isDineIn}
          onChange={handleToggle} // Toggle the Dine In/Take Away state
        />
        <span className="slider"></span>
      </label>
                <p className="status" id="status-1" style={{ marginTop: '-10px',marginRight:'130px' }}>
        {isDineIn ? 'Dine In' : 'Take Away'}
      </p>
              <button className="checkout-button" onClick={initiatePayment} disabled={loading}>
                  {loading ? "Processing..." : "Pay Now"}
              </button>
          </div>
      </div>
    </div>
  );
};

export default Carts;
