import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from "../img/icon-dinner.png";
import logo1 from "../img/logo/green-img.PNG";
import logo2 from "../img/logo/red-img.PNG";
import $ from 'jquery';
import Carts from './CartComponent';
import { useNavigate } from "react-router-dom"; 

const Menu = () => {
  const [ads, setAds] = useState([]);
  const navigate = useNavigate();
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [menuItems, setMenuItems] = useState([]);  // Store menu items
  const [categories, setCategories] = useState([]);  // Store categories
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);  // Store selected category for filter
  const [dishDetails, setDishDetails] = useState({});
  const [page, setPage] = useState(1);  // Pagination
  const [hasMore, setHasMore] = useState(true);  // Whether there's more data
  const [loading, setLoading] = useState(true);  // Loading state
  const [isDineIn, setIsDineIn] = useState(true);  
  const [orderType, setOrderType] = useState('');

  useEffect(() => {
    const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
    setDishDetails(storedDishDetails);
    
    const storedCategoryId = localStorage.getItem('selectedCategoryId');
    setSelectedCategoryId(storedCategoryId);
  }, []);

  useEffect(() => {
    $("#cart-items").css({ display: "block", right: '-300px' }).animate({ right: '0' }, 1000);
  }, [isCartVisible]);

  useEffect(() => {
    // Retrieve the stored order type from localStorage
    const storedOrderType = localStorage.getItem('orderType');
    if (storedOrderType) {
      setOrderType(storedOrderType);
    }
  }, []);
  

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [selectedCategoryId]);


  const [userLoggedIn, setUserLoggedIn] = useState(() => {
    // Check if the user is logged in from localStorage
    return localStorage.getItem('userLoggedIn') === 'true';
  });

useEffect(() => {
    // Check login status on component mount
    const isLoggedIn = localStorage.getItem('userLoggedIn'); // Get login status from localStorage
    setUserLoggedIn(isLoggedIn);
}, []);

  useEffect(() => {
    const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
    setDishDetails(storedDishDetails);
  }, []);


  const handleToggle = () => {
    setIsDineIn(!isDineIn); // Toggle between Dine In and Take Away
  };
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/categories/getall');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);


  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/advertaisment/getall');
        setAds(response.data);
      } catch (error) {
        console.error('Error fetching advertisements:', error);
      }
    };

    fetchAds();
  }, []);

 // Fetch menu items based on category
 const fetchMenuItems = async (currentPage) => {
  let url = `https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/get/alldishes/${currentPage}`;

  console.log(selectedCategoryId)
  // Uncomment if needed for category-specific data
  // if (selectedCategoryId != null) {
  //   url = `https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/get/list/${selectedCategoryId}`;
  // }

  try {
    setLoading(true);  // Start loading

    const response = await axios.get(url);
    if (response.data && response.data.length > 0) {
      setMenuItems((prevItems) => {
        // Append new items for subsequent pages
        if (currentPage > 1) {
          return [...prevItems, ...response.data];
        }
        return response.data;  // Set first page items
      });

      setHasMore(true);  // There's still more data to load
    } else {
      setHasMore(false);  // No more data available
    }
  } catch (error) {
    console.error('Error fetching menu items:', error);
  } finally {
    setLoading(false);  // Stop loading
  }
};

// Fetch the first page on initial load or when the category changes
useEffect(() => {
  fetchMenuItems(1);  // Fetch the first page when component mounts or category changes
  setPage(1);  // Reset to page 1 on category change
}, [selectedCategoryId]);  // Trigger on selectedCategoryId change or on initial mount

// Fetch additional pages based on the page number
useEffect(() => {
  if (page > 1 && hasMore) {
    fetchMenuItems(page);
  }
}, [page]);  // Trigger when page number changes

// Start an interval to increment page if there's more data
useEffect(() => {
  if (hasMore) {
    const intervalId = setInterval(() => {
      setPage((prevPage) => prevPage + 1);  // Increment the page after interval
    }, 1000);  // Set interval to 10 seconds (or your preferred duration)

    return () => clearInterval(intervalId);  // Clean up the interval on unmount
  }
}, [hasMore]);  // Only run if hasMore is true  // Only keep the interval if there are more items to load

  // Handle category selection
  const handleCategoryIdClick = (categoryId) => {
    if (selectedCategoryId !== categoryId) {
      setMenuItems([]); // Clear previous items before applying a new filter
      setLoading(true); // Show loading state while fetching new items
      setPage(1); // Reset page to 1
      setHasMore(true); // Reset the pagination
      setSelectedCategoryId(categoryId); // Set the selected category
    }
  };
  
  

  const initiatePayment = async () => {

    if (!userLoggedIn) {
      // Redirect to login if not logged in
      alert('You need to log in to proceed with the payment.');
      navigate('/login');
      return;
    }

    else{
    setLoading(true);

    // Payment and transaction details
    const apiKey = "74cf3dd4-abf5-4e25-8850-0fd5d3fd1bd1";
    const redirectUrl = 'https://maamaas.com/menu.php'; // Ensure this matches the configured URL
    const callbackUrl = 'https://maamaas.com/menu.php'; // Ensure this matches if needed
    const amount = 1; // Amount in INR

    const paymentData = {
        merchantId: 'M2203XIOCFUVK',
        merchantTransactionId: `MT${Date.now()}`, // Ensure a unique transaction ID
        merchantUserId: "MUID128",
        amount: amount * 100, // Convert to paisa
        redirectUrl: redirectUrl,
        redirectMode: "POST",
        callbackUrl: callbackUrl,
        mobileNumber: "9333999999",
        message: 'Payment for Product/Service',
        email: "info@maamaas.com",
        shortName: "Tutorials Website",
        paymentInstrument: {
            type: "PAY_PAGE",
        },
    };

    try {
        // Encode payload
        const jsonEncodedData = JSON.stringify(paymentData);
        const payloadMain = btoa(jsonEncodedData); // Base64 encode

        // Generate SHA256 hash
        const saltIndex = 1;
        const payload = `${payloadMain}/pg/v1/pay${apiKey}`;
        const sha256Hash = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(payload));

        // Convert hash to hex string
        const sha256Hex = Array.from(new Uint8Array(sha256Hash))
            .map((b) => b.toString(16).padStart(2, '0'))
            .join('');

        const finalXHeader = `${sha256Hex}###${saltIndex}`;

        // Prepare request body
        const requestBody = JSON.stringify({ request: payloadMain });

        // Make the request using Fetch API
        const apiUrl = "/apis/hermes/pg/v1/pay";
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-VERIFY": finalXHeader, // Uncomment if the API requires this header
                "Accept": "application/json",
            },
            body: requestBody,
        });

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
            const errorResponse = await response.json();
            console.error("Error response:", errorResponse);
            throw new Error(`HTTP error! Status: ${response.status} - ${errorResponse.message || "Bad Request"}`);
        }

        // Handle the response
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.success === true) {
            const paymentUrl = responseData.data.instrumentResponse.redirectInfo.url;
            window.location.href = paymentUrl; // Redirect to the payment page
        } else {
            alert("Payment initiation failed: " + responseData.message);
        }
    } catch (error) {
        console.error("Error initiating payment:", error);
        alert("Error initiating payment. Please try again.");
    } finally {
        setLoading(false);
    }
  }
};



  const updateQuantity = (dishId, delta) => {
    setDishDetails((prevItems) => {
      const updatedItems = { ...prevItems };
      if (updatedItems[dishId]) {
        const currentQuantity = updatedItems[dishId].quantity;
        const newQuantity = currentQuantity + delta;
        if (newQuantity <= 0) {
          delete updatedItems[dishId];
        } else {
          updatedItems[dishId].quantity = newQuantity;
        }
      }
      return updatedItems;
    });
  };

  const calculateTotal = () => {
    const subtotal = Object.values(dishDetails).reduce((acc, item) => acc + item.price * item.quantity, 0);
    const cgst = subtotal * 0.09;
    const sgst = subtotal * 0.09;
    const grandTotal = subtotal + cgst + sgst;
    return { subtotal, cgst, sgst, grandTotal };
  };

  const { subtotal, cgst, sgst, grandTotal } = calculateTotal();


 


  const incrementQuantity = (dishId, name, price) => {
    setDishDetails(prev => {
      const newDetails = {
        ...prev,
        [dishId]: {
          quantity: (prev[dishId]?.quantity || 0) + 1,
          name: name || prev[dishId]?.categoryName || prev[dishId]?.subCategory,
          price: price || prev[dishId]?.price,
          dishId: dishId
        }
      };
  
      console.log(newDetails)
      localStorage.setItem('dishDetails', JSON.stringify(newDetails)); // Immediately save to localStorage
      return newDetails;
    });
    
    setIsCartVisible(true);  // Ensure cart is visible after adding
  };
  
  const decrementQuantity = (dishId) => {
    setDishDetails(prev => {
      const currentQuantity = prev[dishId]?.quantity || 0;
      let newDetails;
  
      if (currentQuantity <= 1) {
        // Remove the item from cart if quantity goes below 1
        const { [dishId]: _, ...remainingItems } = prev;
        newDetails = remainingItems;
      } else {
        // Otherwise, decrement the quantity
        newDetails = {
          ...prev,
          [dishId]: {
            ...prev[dishId],
            quantity: currentQuantity - 1
          }
        };
      }
  
      localStorage.setItem('dishDetails', JSON.stringify(newDetails));  // Immediately save to localStorage
  
      const hasItems = Object.values(newDetails).some(item => item.quantity > 0);
      if (!hasItems) {
        setIsCartVisible(false);
      }
  
      return newDetails;
    });
  };
  

// Sync `dishDetails` from localStorage immediately after page refresh or state updates



  return (
    <div className="menu-container">
      <div className="categories-sidebar">
        <h3>Categories</h3>
        <ul>
          {categories.map(category => (
            <li
              key={category.categoryId}
              onClick={() => handleCategoryIdClick(category.categoryId)}
              style={{ cursor: 'pointer', fontWeight: selectedCategoryId === category.categoryId ? 'bold' : 'normal' }}
            >
              <img src={logo} alt="Logo" height="20px" />
              {category.categoryName}
            </li>
          ))}
        </ul>
      </div>



      <div className="menu-container1">
      <div className="menu-items">
        {menuItems.map(item => (
          <div className="menu-item-card" key={item.dishId}>
            <img src={`data:image/png;base64,${item.image}`} alt={item.name} className="menu-item-image" />
            <h4 className="menu-item-name">{item.categoryName || item.subCategory}</h4>
            <p className="menu-item-price">₹ {item.price}</p>
            <div className="menu-item-stock-container">
              <p className="menu-item-stock">{item.stock ? "In Stock" : "Out of Stock"}</p>
              <span className={`dish-icon ${item.tag === 'Veg' ? 'veg' : 'non-veg'}`}>
                {item.tag === 'Veg' ? (
                  <img src={logo1} alt="Veg" className="dish-icon-img" />
                ) : (
                  <img src={logo2} alt="Non-Veg" className="dish-icon-img" />
                )}
              </span>
            </div>

            {item.stock ? (
              dishDetails[item.dishId]?.quantity > 0 ? (
                <div className="quantity-controls">
                  <button className="decrement-button" onClick={() => decrementQuantity(item.dishId)}>-</button>
                  <span className="quantity-count">{dishDetails[item.dishId]?.quantity || 0}</span>
                  <button className="increment-button" onClick={() => incrementQuantity(item.dishId, item.categoryName || item.subCategory, item.price)}>+</button>
                </div>
              ) : (
                <button className="add-button" onClick={() => incrementQuantity(item.dishId, item.categoryName || item.subCategory, item.price)}>Add</button>
              )
            ) : (
              <button className="add-button" disabled>Out of Stock</button>
            )}
          </div>
        ))}
      </div>
      </div>

      <div className="col-lg-3 col-md-6 items-container" style={{ paddingBottom: '10px', position: 'fixed', right: '1%' }}>
        <div className="marquee-container" style={{ overflow: 'hidden', width: '100%' }}>
          <div className="marquee" style={{ display: 'flex', flexDirection: 'column', animation: 'marquee 15s linear infinite' }}>
            {ads.map(item => (
              <div key={item.id} className="menu-item-box item" style={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', backgroundColor: '#EE4B2E', marginBottom: '20px' }}>
                <div className="menu-item" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ee4b2e' }}>
                  <div className="menu-item-img">
                    <div className="image-anime">
                      <img src={`data:image/png;base64,${item.image}`} alt="Advertisement" style={{ maxHeight: '180px', maxWidth: '100%' }} />
                    </div>
                  </div>
                </div>
                <p style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>{item.description}</p>
              </div>
            ))}
          </div>

          {isCartVisible && (
           <div id="cart-items" className="cart-container">
           <h5 className="cart-title">View Cart</h5>
           <table className="cart-table">
               <thead>
                   <tr>
                       <th>SNO</th>
                       <th>ITEM</th>
                       <th>PRICE</th>
                       <th>QTY</th>
                       <th>TOTAL</th>
                   </tr>
               </thead>
               <tbody>
                   {Object.values(dishDetails).map((item, index) => (
                       <tr key={index}>
                           <td>{index + 1}</td>
                           <td>{item.name}</td>
                           <td>₹{item.price}</td>
                           <td>
                               <div className="qty-container">
                                   <button className="qty-button" onClick={() => updateQuantity(item.dishId, -1)}>-</button>
                                   <span className="qty-count">{item.quantity}</span>
                                   <button className="qty-button" onClick={() => updateQuantity(item.dishId, 1)}>+</button>
                               </div>
                           </td>
                           <td>₹{(item.price * item.quantity).toFixed(2)}</td>
                       </tr>
                   ))}
               </tbody>
           </table>
     
           {/* Display totals */}
           <div className="cart-totals">
               <p>Total: <span>₹{subtotal.toFixed(2)}</span></p>
               <p>CGST (9%): <span>₹{cgst.toFixed(2)}</span></p>
               <p>SGST (9%): <span>₹{sgst.toFixed(2)}</span></p>
               <p>Grand Total: <span>₹{grandTotal.toFixed(2)}</span></p>
     
               <div className="cart-options">
                   {/* <ToggleButton onToggle={(dineIn) => setIsDineIn(dineIn)} /> */}
                   <label className="switch">
             <input
               type="checkbox"
               className="toggleSwitch"
               id="service"
               checked={isDineIn}
               onChange={handleToggle} // Toggle the Dine In/Take Away state
             />
             <span className="slider"></span>
           </label>
           <p
        className="status"
        id="status-1"
        style={{ marginTop: '-10px', marginRight: '130px' }}
      >
        {orderType ? orderType : 'No selection'} {/* Show either 'Dine In' or 'Take Away' */}
      </p>

                   <button className="checkout-button" onClick={initiatePayment}>
                       {"Pay Now"}
                   </button>
               </div>
           </div>
         </div>
         
          )
          }
        </div>
      </div>
    </div>
  );
};

export default Menu