import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import logo from "../img/MAAMAAS_LOGO.png";
import 'boxicons/css/boxicons.min.css';
import axios from "axios";


const Login = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [isLogin, setIsLogin] = useState(true); // Toggle between login and signup
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false); // For forgot password modal
  const [identifier, setIdentifier] = useState(""); // State for storing identifier
  const [password, setPassword] = useState(""); // State for storing password
  const [errorMessage, setErrorMessage] = useState(""); // To display error messages
  const [showPassword, setShowPassword] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(""); 
  const [email, setEmail] = useState("");
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState(""); // Separate forgot password error state
  const [forgotPasswordSuccessMessage, setForgotPasswordSuccessMessage] = useState(""); 
  const [successMessage, setSuccessMessage] = useState("");
  // Signup state management
  const [userName, setUserName] = useState(""); // State for storing userName
  const [mobileNumber, setMobileNumber] = useState(""); // State for storing mobileNumber
  const [emailId, setEmailId] = useState(""); // State for storing emailId
  const [signupErrorMessage, setSignupErrorMessage] = useState(""); // To display signup error messages

  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const toggleForgotPassword = () => {
    setForgotPasswordModal(!forgotPasswordModal);
  };


  const handleSubmit = async () => {
    // Clear previous messages
    setForgotPasswordErrorMessage("");
    setForgotPasswordSuccessMessage("");

    try {
      const response = await axios.post("https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/forgot/password", {
        identifier: email,
      });

      if (response.status === 200) {
        setForgotPasswordSuccessMessage("Password reset email sent. Please check your inbox.");
      } else {
        setForgotPasswordErrorMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      setForgotPasswordErrorMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };


  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault(); 

    const requestBody = {
      identifier: identifier,
      password: password,
    };

    try {
      const response = await fetch('https://dataserver.maamaas.com/Mamaswebsite-0.0.1-SNAPSHOT/login/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('userLoggedIn', 'true');
        localStorage.setItem('userData', JSON.stringify(data));
        navigate('/home'); 
        window.location.reload(); 
      } else {
        setLoginErrorMessage(data.message || "Login failed, please check your credentials.");
      }
    } catch (error) {
      setLoginErrorMessage("An error occurred during login. Please try again later.");
    }
  };


  // Handle signup
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row" style={{ justifyContent: "center", textAlign: "center" }}>
          <div className="container1 p-5">
            {/* Login Form */}
              <form id="loginForm" onSubmit={handleLogin}>
                <div className="login-logo logo-normal">
                  <img src={logo} alt="" style={{ height: "90px" }} />
                </div>
                <h6>Login</h6>
                <div className="input-box">
  <label>User Name</label>
  <input
    name="identifier"
    placeholder="Enter Email/Mobile Number"
    type="text"
    value={identifier}
    onChange={(e) => setIdentifier(e.target.value)}
    required
  />
  <i className="bx bxs-user"></i>
</div>
<div className="input-box1">
  <label>Password</label>
  <input
    name="password"
    type={showPassword ? "text" : "password"} 
    placeholder="Enter Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  <i
        className={`bx ${showPassword ? 'bxs-show' : 'bxs-hide'}`} // Toggle between eye and eye-slash icon
        onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
        style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickability
      ></i>
</div>

                {/* Error Message */}
                {loginErrorMessage && (
                <div style={{ color: "red", marginTop: "20px" }}>
                  {loginErrorMessage}
                </div>
                )}
                <div className="remember-forgot">
                  <br />
                  <a href="#" onClick={toggleForgotPassword}>
                    Forgot password?
                  </a>
                </div>
                <button type="submit" className="btn">Login</button>
                <div className="register-link">
                  <p>
                    Don't have an account? <a href="/signup">Signup</a>
                  </p>
                </div>
              </form>
    
            
             
    
          </div>
        </div>
      </div>

    {/* Forgot Password Modal */}
{forgotPasswordModal && (
  <div id="forgotPasswordModal" className="modal">
    <div className="modal-content">
      <span className="close" onClick={toggleForgotPassword}>&times;</span>
      <h2 style={{ textAlign: "center" }}>Forgot Password</h2>
      <br />
      <div className="email-data">
        <p>We will send you an email to reset your password.</p>
      </div>
      <div className="input-boxforgot">
        <label>EMAIL</label>
        <div className="email-input">
          <input type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} required />
          <i className="bx bxs-envelope"></i>
        </div>
      </div>
      {forgotPasswordErrorMessage && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {forgotPasswordErrorMessage}
              </div>
            )}
            {/* Display forgot password success message */}
            {forgotPasswordSuccessMessage && (
              <div style={{ color: "green", marginTop: "50px" }}>
                {forgotPasswordSuccessMessage}
              </div>
            )}
      <button className="btn2"  onClick={handleSubmit}>Submit</button>
    </div>
  </div>
)}
  </div>
  );
};

export default Login;
