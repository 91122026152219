import React from 'react';
import CountUp from 'react-countup';
import img1 from '../img/info-bar-1.jpg';
import img2 from '../img/info-bar-2.jpg';
import img3 from '../img/info-bar-3.jpg';
import img4 from '../img/info-bar-4.jpg';


const StackCards = () => {
  const cardsData = [
    { image: img1, number: 56, title: 'Food Varieties' },
    { image: img2, number: 7, title: 'Awards' },
    { image: img3, number: 125, title: 'Happy Foodies' },
    { image: img4, number: 3, title: 'Branches' },
  ];

  return (
    <div className="cards-container">
      {cardsData.map((card, index) => (
        <div key={index} className="card">
          <div
            className="card-bg"
            style={{ backgroundImage: `url(${card.image})` }}
          >
            <div className="card-content">
              <h2>
                <CountUp start={0} end={card.number} duration={2} />
              </h2>
              <p>{card.title}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StackCards;
