
import React from "react";
import NavBar from "./Component/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Component/Footer";
import Home from "./Component/Home";
import Menu from "./Component/Menu";
import Login from "./Component/Login";
import Signup from "./Component/SignUp";
import ProfilePage from "./Component/MyProfile";
import PastOrders from "./Component/Orders";
import Apps  from "./Component/CartVisible";
// import logo from './logo.svg';
// import './App.css';
function App() {
  return (
    
    <div>
    <Router>
      <NavBar />
      <Routes>

        
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/orders" element={<PastOrders />} />
        

        {/* <Route path="/about-us" element={<AboutUs />}></Route> */}
        

      </Routes>

      <Footer></Footer>

    </Router>
  </div>
  );
}

export default App;
