import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const PastOrders = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(8); // Example total pages

  useEffect(() => {
    // Simulating fetching order data

    

    const fetchedOrders = [
      {
        billNumber: 1,
        orderDate: 'September 10, 2024 10:31 AM',
        items: [
          {
            id: 1,
            name: 'CURD RICE',
            image: 'https://via.placeholder.com/150',
            price: '₹1',
          },
        ],
      },

      

      {
        billNumber: 2,
        orderDate: 'September 10, 2024 10:35 AM',
        items: [
          {
            id: 2,
            name: 'CURD RICE',
            image: 'https://via.placeholder.com/150',
            price: '₹1',
          },
        ],
      },
    ];

    setOrders(fetchedOrders);
  }, [currentPage]);

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLogout = () => {
    // Add your logout functionality here
    console.log("Logging out...");
};


  return (
    <div className="profile-container1">
    <section id="vertical_tab_nav">
      <ul className="tabsd">
        <li>
          <Link to="/profile" className="tab">
            <i className="fas fa-user profile-icon"></i> Profile
          </Link>
        </li>
        <li>
          <Link to="/orders" className="tab active">
            <i className="fas fa-shopping-cart profile-icon"></i> Orders
          </Link>
        </li>
        <li>
          <Link to="/payments" className="tab">
            <i className="fas fa-credit-card profile-icon"></i> Payments
          </Link>
        </li>
        <li>
          <a href="#logout" onClick={handleLogout} className="tab">
            <i className="fas fa-sign-out-alt profile-icon"></i> Logout
          </a>
        </li>
      </ul>
    </section>

    <div className="containerhj mt-5 past-orders-container">
      <h3 className="text-center">Past Orders</h3>
      <div className="orders-scrollable">
      <div className="orders-list">
        {orders.map((order) => (
          <div key={order.billNumber} className="order-card mb-3">
            <div className="order-header">
              <span className="bill-number">Bill Number: {order.billNumber}</span>
              <span className="order-date">Ordered on: {order.orderDate}</span>
            </div>

            {order.items.map((item) => (
              <div key={item.id} className="order-body">
                <img
                  src={item.image}
                  alt={item.name}
                  className="order-image"
                />
                <div className="order-details">
                  <h5 className="item-name">{item.name}</h5>
                  <p><strong>Item:</strong> {item.name}</p>
                  <p><strong>Price:</strong> {item.price}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
</div>
      {/* Pagination Controls (stacked vertically) */}
      <div className="pagination-controls text-center">
        <button
          className="btnw btnw-danger mb-2"
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="page-info">Page {currentPage} of {totalPages}</span>
        <button
          className="btnw btnw-danger mt-2"
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
    </div>
  );
};

export default PastOrders;
