import React, { useState, useEffect, createContext } from 'react';
import logo from "../img/maamaa.png"; // Path to logo
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // Import axios for API requests
import $ from 'jquery'; // Import jQuery
import Carts from './CartComponent';
import { useNavigate } from "react-router-dom"; 
import { createPopper } from '@popperjs/core';

// Cart Context
const CartContext = createContext();



// ToggleButton component
const NavBar = () => {
    const navigate = useNavigate();

    const [isCartVisible, setIsCartVisible] = useState(false);
    const [dishDetails, setDishDetails] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isDineIn, setIsDineIn] = useState(true); // State for Dine In / Take Away
    const [loading, setLoading] = useState(false); // State for loading
    const [isSticky, setIsSticky] = useState(false);
    // Fetch dish details from localStorage on mount

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

    useEffect(() => {
        const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
        setDishDetails(storedDishDetails);
    }, []);

   
    


    useEffect(() => {
        // Optional: Initialize dropdown manually
        const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
        dropdownElementList.forEach(dropdownToggle => {
          new window.bootstrap.Dropdown(dropdownToggle);
        });
      }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Add sticky class when scrolled past a certain point (e.g., 100px)
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    


    // Sync dish details with localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('dishDetails', JSON.stringify(dishDetails));
    }, [dishDetails]);

    // Toggle Cart Dropdown
    useEffect(() => {
        if (isCartVisible) {
          $("#cart-items")
            .css({ display: "block", right: '-300px' })
            .animate({ right: '0' }, 1000);
        } else {
          // Nudging it barely out of the view
          $("#cart-items").animate({ right: '5px' }, 300); // Move slightly to the right
        }
      }, [isCartVisible]);



      const [userLoggedIn, setUserLoggedIn] = useState(() => {
        // Check if the user is logged in from localStorage
        return localStorage.getItem('userLoggedIn') === 'true';
      });

    useEffect(() => {
        // Check login status on component mount
        const isLoggedIn = localStorage.getItem('userLoggedIn'); // Get login status from localStorage
        setUserLoggedIn(isLoggedIn);
    }, []);
    


    // const initiatePayment = async () => {
    //     setLoading(true);
    
    //     const apiKey = "74cf3dd4-abf5-4e25-8850-0fd5d3fd1bd1"; // sandbox or test API key
    //     const redirectUrl = 'payment-success.php';
    
    //     // Set transaction details
    //     const orderId = `order_${Date.now()}`;
    //     const name = "Tutorials Website";
    //     const email = "info@maamaas.com";
    //     const mobile = 9333999999;
    //     const amount = 10; // Amount in INR
    //     const description = 'Payment for Product/Service';
    
    //     const paymentData = {
    //         merchantId: 'M2203XIOCFUVK',
    //         merchantTransactionId: "MT7850590068188105",
    //         merchantUserId: "MUID128",
    //         amount: amount * 100, // Convert to paisa
    //         redirectUrl: redirectUrl,
    //         redirectMode: "POST",
    //         callbackUrl: redirectUrl,
    //         mobileNumber: mobile,
    //         message: description,
    //         email: email,
    //         shortName: name,
    //         paymentInstrument: {
    //             type: "PAY_PAGE",
    //         },
    //     };
    
    //     try {
    //         // Encode payload
    //         const jsonEncodedData = JSON.stringify(paymentData);
    //         const payloadMain = btoa(jsonEncodedData); // Base64 encode
    
    //         // Generate SHA256 hash
    //         const saltIndex = 1;
    //         const payload = `${payloadMain}/pg/v1/pay${apiKey}`;
    //         const sha256Hash = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(payload));
    
    //         // Convert hash to hex string
    //         const sha256Hex = Array.from(new Uint8Array(sha256Hash))
    //             .map((b) => b.toString(16).padStart(2, '0'))
    //             .join('');
    
    //         const finalXHeader = `${sha256Hex}###${saltIndex}`;
    
    //         // Prepare request body
    //         const requestBody = JSON.stringify({ request: payloadMain });
    
    //         // Make the request using Axios to the proxy endpoint
    //         const response = await axios.post(
    //             "https://api.phonepe.com/apis/hermes/pg/v1/pay", // Use the proxy endpoint
    //             requestBody,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "X-VERIFY": finalXHeader,
    //                     "Accept": "application/json",
    //                 },
    //             }
    //         );
    
    //         // Handle the response
    //         const responseData = response.data;
    //         console.log(responseData);
    //         if (responseData.success === 200) {
    //             const paymentUrl = responseData.data.instrumentResponse.redirectInfo.url;
    //             window.location.href = paymentUrl; // Redirect to the payment page
    //         } else {
    //             alert("Payment initiation failed: " + responseData.message);
    //         }
    //     } catch (error) {
    //         console.error("Error initiating payment:", error);
    //         alert("Error initiating payment. Please try again.");
    //     } finally {
    //         setLoading(false);
    //     }
    // };


    // const handleToggle = () => {
    //     setIsDineIn(!isDineIn); // Toggle between Dine In and Take Away
    //   };
    

    const handleLogin = () => {
        // Simulate login action
        // setUserLoggedIn(true);
        // localStorage.setItem("userLoggedIn", "true");
        navigate('/login');
    };

    const handleLogout = () => {
        // Simulate logout action
        localStorage.removeItem('userLoggedIn'); // Remove login status from localStorage
        setUserLoggedIn(false);

        // Navigate to logout page
        navigate('/login'); // Replace '/logout' with the actual path of your logout page
    };
      
    useEffect(() => {
        const interval = setInterval(() => {
          const storedDishDetails = JSON.parse(localStorage.getItem("dishDetails")) || {};
          setDishDetails(storedDishDetails);
          console.log("Updated dishDetails: ", storedDishDetails);
        }, 10); // Runs every 1 second (1000 milliseconds)
    
        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
      }, []);
      

    const handleCartIconClick = () => {
        const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
        setDishDetails(storedDishDetails);
        console.log(storedDishDetails)
        setIsCartVisible(prevState => !prevState);
    };

    const updateQuantity = (dishId, delta) => {
        setDishDetails((prevItems) => {
            const updatedItems = { ...prevItems };
    
            // Check if the dish exists in the updatedItems
            if (updatedItems[dishId]) {
                // Calculate the new quantity based on delta
                const currentQuantity = updatedItems[dishId].quantity;
                const newQuantity = currentQuantity + delta; // Adjust quantity by delta
    
                // If new quantity is less than or equal to zero, remove the dish
                if (newQuantity <= 0) {
                    delete updatedItems[dishId];
                } else {
                    // Otherwise, update the quantity
                    updatedItems[dishId].quantity = newQuantity;
                }
            }
            return updatedItems;
        });
    };
    


    // const calculateTotal = () => {
    //     return Object.values(dishDetails).reduce((total, item) => total + item.price * item.quantity, 0);
    // };



    const calculateTotal = () => {
        const subtotal = Object.values(dishDetails).reduce((acc, item) => acc + item.price * item.quantity, 0);
        const cgst = subtotal * 0.09;
        const sgst = subtotal * 0.09;
        const grandTotal = subtotal + cgst + sgst;
        return { subtotal, cgst, sgst, grandTotal };
    };

    const { subtotal, cgst, sgst, grandTotal } = calculateTotal();

   
    

    return (
        <CartContext.Provider value={{ dishDetails, updateQuantity, calculateTotal }}>
            <header className="main-header">
                <nav className="navbar">
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <img src={logo} alt="Logo" height="80px" style={{ borderRadius: '50%' }} />
                            <Link to="/home" className="brand-name-link">
                                <h1 className="brand-name">MAAMAA'S</h1>
                            </Link>
                            <Link to="/menu" className="brand-name-link">
                                <h1 className="brand-name1">Menu</h1>
                            </Link>
                        </div>

                        <div className="d-flex align-items-center social-login-cart">
                            <Link to="https://www.facebook.com/people/Maamaas-House/pfbid028rCAJ3bak6r7d2sa2FhWtykbD3WhrP734EdZAaisUphFNXLjTLLhPDSeSA8opw5Pl/" className="icon-link">
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </div>
                            </Link>
                            <Link to="https://www.instagram.com/maamaashouse/" className="icon-link">
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </div>
                            </Link>
                            <Link to="https://www.instagram.com/maamaashouse/" className="icon-link">
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </div>
                            </Link>
                            <Link to="https://www.linkedin.com/in/maamaas-house-282760306/" className="icon-link">
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </div>
                            </Link>
                            <Link to="https://www.youtube.com/channel/UC546X0iWjNKOu7VKWA4djMw" className="icon-link">
                                <div className="icon-circle">
                                    <FontAwesomeIcon icon={faYoutube} />
                                </div>
                            </Link>

                            {/* <Link to="/login" className="login-link">Login</Link> */}
                            <ul className="navbar-nav">
            {userLoggedIn ? (
                <li className="nav-item">
               <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
      <button
        className="btnc dropdown-toggle"
        style={{ backgroundColor: "#f2735c", color: "#fff" ,borderColor:'#fff'}}
        type="button"
        onClick={toggleDropdown} // Toggle dropdown on click
      >
        Account
      </button>
      {isOpen && (
        <ul
          className="dropdown-menu"
          style={{
            position: "absolute",  // Ensure dropdown is placed over other content
            top: "100%",           // Position just below the button
            left: "0",
            zIndex: 6666,          // Ensure it appears above other elements
            display: "block",
            backgroundColor: "#F44336", // Dropdown background
            padding: "10px",
            border: "1px solid #fff", // Border color matches the background color (white)
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow for visibility
          }}
        >
          <li>
            <a className="nav-link dropdown-item" href="/profile">
              Profile
            </a>
          </li>
          <li>
            <a
              className="nav-link dropdown-item"
              href="#"
              onClick={handleLogout}
              style={{ fontWeight: 800, fontSize: "15px" }}
            >
              Logout
            </a>
          </li>
        </ul>
      )}
    </div>

                </li>
            ) : (
                <li className="nav-item">
                    <a
                        className="nav-link"
                        style={{ fontWeight: 800, fontSize: '15px' }}
                        onClick={handleLogin} // Simulate login
                    >
                        Login
                    </a>
                </li>
            )}
           
        </ul>
    

                            <div className="cart-link" onClick={handleCartIconClick}>
                                <FontAwesomeIcon icon={faCartShopping} />
                                <span>({Object.values(dishDetails).reduce((sum, item) => sum + item.quantity, 0)})</span>
                            </div>

                         


                        </div>
                      
                    </div>
                    
                </nav>
                
            </header>
            
           
        </CartContext.Provider>
    );
};

export default NavBar;
